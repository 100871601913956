import React, { useState, useEffect, useRef } from 'react';
import io from 'socket.io-client';
import * as mediasoupClient from 'mediasoup-client';

const SERVER_URL = 'https://liveserver.nowdigitaleasy.com:3001';

function App() {

  const [joined, setJoined] = useState(true);
  const [remoteStreams, setRemoteStreams] = useState([]);
  const localVideoRef = useRef(null);
  const remoteVideosRef = useRef(null);

  const deviceRef = useRef(null);
  const socketRef = useRef(null);
  const sendTransportRef = useRef(null);
  const receiveTransportRef = useRef(null);
  let params
  let rtpCapabilities
  let device
  let producerTransport
  let consumerTransport
  let producer
  let consumer



  useEffect(() => {

    socketRef.current = io(SERVER_URL,{secure:true});
    socketRef.current.on('connect', () => {
      console.log('Socket connected with ID:', socketRef.current.id);
    });
    return () => {
      if (socketRef.current) socketRef.current.disconnect();
    };

  }, []);


  const streamSuccess = async (stream) => {
    const localVideo = document.querySelector('#localVideo');
  
    localVideo.srcObject = stream
    const track = stream.getVideoTracks()[0]
    
    params = {
      track,
      ...params
    }
  }

  const getLocalStream = () => {
    navigator.mediaDevices.getUserMedia({
      audio: false,
      video: {
        width: {
          min: 640,
          max: 1920,
        },
        height: {
          min: 400,
          max: 1080,
        }
      }
    })
    .then(streamSuccess)
    .catch(error => {
      console.log(error.message)
    })
  }

  const getRtpCapabilities = () => {
    // make a request to the server for Router RTP Capabilities
    // see server's socket.on('getRtpCapabilities', ...)
    // the server sends back data object which contains rtpCapabilities
    socketRef.current.emit('getRtpCapabilities', (data) => {
      
      console.log(`Router RTP Capabilities... ${data.rtpCapabilities}`)
  
      // we assign to local variable and will be used when
      // loading the client Device (see createDevice above)
      rtpCapabilities = data.rtpCapabilities
    })
  }

  const createDevice = async () => {
    try {
      device = new mediasoupClient.Device()
  
      // https://mediasoup.org/documentation/v3/mediasoup-client/api/#device-load
      // Loads the device with RTP capabilities of the Router (server side)
      await device.load({
        // see getRtpCapabilities() below
        routerRtpCapabilities: rtpCapabilities
      })
  
      console.log('RTP Capabilities', device.rtpCapabilities)
  
    } catch (error) {
      console.log(error)
      if (error.name === 'UnsupportedError')
        console.warn('browser not supported')
    }
  }

  const createSendTransport = () => {
    // see server's socket.on('createWebRtcTransport', sender?, ...)
    // this is a call from Producer, so sender = true
    socketRef.current.emit('createWebRtcTransport', { sender: true }, ({ params }) => {
      // The server sends back params needed 
      // to create Send Transport on the client side
      if (params.error) {
        console.log(params.error)
        return
      }
  
      console.log(params)
  
      // creates a new WebRTC Transport to send media
      // based on the server's producer transport params
      // https://mediasoup.org/documentation/v3/mediasoup-client/api/#TransportOptions
      producerTransport = device.createSendTransport(params)
  
      // https://mediasoup.org/documentation/v3/communication-between-client-and-server/#producing-media
      // this event is raised when a first call to transport.produce() is made
      // see connectSendTransport() below
      producerTransport.on('connect', async ({ dtlsParameters }, callback, errback) => {
        try {
          // Signal local DTLS parameters to the server side transport
          // see server's socket.on('transport-connect', ...)
          await socketRef.current.emit('transport-connect', {
            dtlsParameters,
          })
  
          // Tell the transport that parameters were transmitted.
          callback()
  
        } catch (error) {
          errback(error)
        }
      })
  
      producerTransport.on('produce', async (parameters, callback, errback) => {
        console.log(parameters)
  
        try {
          // tell the server to create a Producer
          // with the following parameters and produce
          // and expect back a server side producer id
          // see server's socket.on('transport-produce', ...)
          await socketRef.current.emit('transport-produce', {
            kind: parameters.kind,
            rtpParameters: parameters.rtpParameters,
            appData: parameters.appData,
          }, ({ id }) => {
            // Tell the transport that parameters were transmitted and provide it with the
            // server side producer's id.
            callback({ id })
          })
        } catch (error) {
          errback(error)
        }
      })
    })
  }

  const connectSendTransport = async () => {
    // we now call produce() to instruct the producer transport
    // to send media to the Router
    // https://mediasoup.org/documentation/v3/mediasoup-client/api/#transport-produce
    // this action will trigger the 'connect' and 'produce' events above
    producer = await producerTransport.produce(params)
  
    producer.on('trackended', () => {
      console.log('track ended')
  
      // close video track
    })
  
    producer.on('transportclose', () => {
      console.log('transport ended')
  
      // close video track
    })
  }

  const createRecvTransport = async () => {
    // see server's socket.on('consume', sender?, ...)
    // this is a call from Consumer, so sender = false
    await socketRef.current.emit('createWebRtcTransport', { sender: false }, ({ params }) => {
      // The server sends back params needed 
      // to create Send Transport on the client side
      if (params.error) {
        console.log(params.error)
        return
      }
  
      console.log(params)
  
      // creates a new WebRTC Transport to receive media
      // based on server's consumer transport params
      // https://mediasoup.org/documentation/v3/mediasoup-client/api/#device-createRecvTransport
      consumerTransport = device.createRecvTransport(params)
  
      // https://mediasoup.org/documentation/v3/communication-between-client-and-server/#producing-media
      // this event is raised when a first call to transport.produce() is made
      // see connectRecvTransport() below
      consumerTransport.on('connect', async ({ dtlsParameters }, callback, errback) => {
        try {
          // Signal local DTLS parameters to the server side transport
          // see server's socket.on('transport-recv-connect', ...)
          await socketRef.current.emit('transport-recv-connect', {
            dtlsParameters,
          })
  
          // Tell the transport that parameters were transmitted.
          callback()
        } catch (error) {
          // Tell the transport that something was wrong
          errback(error)
        }
      })
    })
  }

  const connectRecvTransport = async () => {
    await socketRef.current.emit(
      'consume',
      {
        rtpCapabilities: device.rtpCapabilities,
      },
      async ({ params }) => {
        if (params.error) {
          console.log('Cannot Consume');
          return;
        }
  
        console.log(params);
  
        consumer = await consumerTransport.consume({
          id: params.id,
          producerId: params.producerId,
          kind: params.kind,
          rtpParameters: params.rtpParameters,
        });
  
        const { track } = consumer;
  
        if (remoteVideosRef.current) {
          remoteVideosRef.current.srcObject = new MediaStream([track]);
          console.log("Remote video stream set:", remoteVideosRef.current.srcObject.getVideoTracks());
        }
  
        // Resume the consumer on the server side
        socketRef.current.emit('consumer-resume');
      }
    );
  };
  
   

  return (
    <div id="video">
    <table>
      <thead>
        <tr>
          <th>Local Video</th>
          <th>Remote Video</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>
            <div id="sharedBtns">
              <video id="localVideo" autoPlay className="video" />
            </div>
          </td>
          <td>
            <div id="sharedBtns">
            <video ref={remoteVideosRef} id="remoteVideo" autoPlay className="video" />
            </div>
          </td>
        </tr>
        <tr>
          <td>
            <div id="sharedBtns">
              <button onClick={getLocalStream} id="btnLocalVideo">1. Get Local Video</button>
            </div>
          </td>
        </tr>
        <tr>
          <td colSpan="2">
            <div id="sharedBtns">
              <button onClick={getRtpCapabilities} id="btnRtpCapabilities">2. Get Rtp Capabilities</button>
              <br />
              <button onClick={createDevice} id="btnDevice">3. Create Device</button>
            </div>
          </td>
        </tr>
        <tr>
          <td>
            <div id="sharedBtns">
              <button onClick={createSendTransport} id="btnCreateSendTransport">4. Create Send Transport</button>
              <br />
              <button onClick={connectSendTransport} id="btnConnectSendTransport">5. Connect Send Transport & Produce</button>
            </div>
          </td>
          <td>
            <div id="sharedBtns">
              <button onClick={createRecvTransport} id="btnRecvSendTransport">6. Create Recv Transport</button>
              <br />
              <button onClick={connectRecvTransport} id="btnConnectRecvTransport">7. Connect Recv Transport & Consume</button>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  );
}

export default App;
